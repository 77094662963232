export const AUTH = {
  SET_ERROR_MSG: 'AUTH__SET_ERROR_MSG',
  SET_USER: 'AUTH__SET_USER'
};

export const FORM_UPLOAD = {
  SET_CURRENT_UPLOADS: 'FORM_UPLOAD__SET_CURRENT_UPLOADS',
  SET_KEYED_FILES: 'FORM_UPLOAD__SET_KEYED_FILES',
  SET_CLOUD_STATUS: 'FORM_UPLOAD__SET_CLOUD_STATUS',
  SET_ERROR: 'FORM_UPLOAD__SET_ERROR',
  SET_STATUS: 'FORM_UPLOAD__SET_STATUS'
};

export const SOCKET = {
  CONNECT: 'SOCKET__CONNECT',
  DISCONNECT: 'SOCKET__DISCONNECT',
  SUBSCRIBE_TASK_DETAIL: 'SOCKET__SUBSCRIBE_TASK_DETAIL',
  SUBSCRIBE_TASK_LIST: 'SOCKET__SUBSCRIBE_TASK_LIST'
};

export const TASK_DETAIL = {
  SET_ACTIVITY: 'TASK_DETAIL__SET_ACTIVITY',
  SET_ASK_LOG: 'TASK_DETAIL__SET_ASK_LOG',
  SET_AVAILABLE_TAX_RETURNS: 'TASK_DETAIL__SET_AVAILABLE_TAX_RETURNS',
  SET_COMMENTS: 'TASK_DETAIL__SET_COMMENTS',
  SET_ESC_ERRORS: 'TASK_DETAIL__SET_ESC_ERRORS',
  SET_LOADING: 'TASK_DETAIL__SET_LOADING',
  SET_MODAL_TYPE: 'TASK_DETAIL__SET_MODAL_TYPE',
  SET_OPS_INFO_VISIBLE: 'TASK_DETAIL__SET_OPS_INFO_VISIBLE',
  SET_RES_MSG: 'TASK_DETAIL__SET_RES_MSG',
  SET_SUBSTAGES_VISIBLE: 'TASK_DETAIL__SET_SUBSTAGES_VISIBLE',
  SET_TASK: 'TASK_DETAIL__SET_TASK',
  SET_TP_INFO_VISIBLE: 'TASK_DETAIL__SET_TP_INFO_VISIBLE',
  SET_UPLOADS: 'TASK_DETAIL__SET_UPLOADS'
};

export const TASK_LIST = {
  ADD_PENDING_RETURN_DOWNLOAD_USER: 'TASK_LIST__ADD_PENDING_RETURN_DOWNLOAD_USER',
  REMOVE_PENDING_RETURN_DOWNLOAD_USER: 'TASK_LIST__REMOVE_PENDING_RETURN_DOWNLOAD_USER',
  RESET_FILTERS: 'TASK_LIST__RESET_FILTERS',
  SET_ASSIGNEE: 'TASK_LIST__SET_ASSIGNEE',
  SET_BULK_EDIT_MODE: 'TASK_LIST__SET_BULK_EDIT_MODE',
  SET_BULK_EDIT_TASKS: 'TASK_LIST__SET_BULK_EDIT_TASKS',
  SET_LOADING: 'TASK_LIST__SET_LOADING',
  SET_ORDER: 'TASK_LIST__SET_ORDER',
  SET_PAGE: 'TASK_LIST__SET_PAGE',
  SET_RES_MSG: 'TASK_LIST__SET_RES_MSG',
  SET_SEARCH_FILTER: 'TASK_LIST__SET_SEARCH_FILTER',
  SET_SHOW_ALL: 'TASK_LIST__SET_SHOW_ALL',
  SET_STAGE_COUNTS: 'TASK_LIST__SET_STAGE_COUNTS',
  SET_STAGE_FILTER: 'TASK_LIST__SET_STAGE_FILTER',
  SET_STAGE_LIST: 'TASK_LIST__SET_STAGE_LIST',
  SET_SUBSTAGE_COUNTS: 'TASK_LIST__SET_SUBSTAGE_COUNTS',
  SET_SUBSTAGE_FILTER: 'TASK_LIST__SET_SUBSTAGE_FILTER',
  SET_TASKS: 'TASK_LIST__SET_TASKS',
  SET_YEAR: 'TASK_LIST__SET_YEAR'
};

export const USERS = {
  SET_BOOKKEEPERS: 'USERS__SET_BOOKEEPERS',
  SET_ERROR_MESSAGE: 'USERS__SET_ERROR_MESSAGE',
  SET_LOADING: 'USERS__SET_LOADING',
  SET_USERS: 'USERS__SET_USERS'
};
